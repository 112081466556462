/****************************************************/
// Capa de Servicios para el modulo de Autenticacion.

import axios from "axios";
import store from "@/store/store.js";
import router from "@/router";

export default new (class {
  #baseUrl = null;
  data = null;
  user = null;
  status = null;
  error_message = "";

  constructor() {
    this.#baseUrl = store.getters.getBaseURL + "/auth";
  }

  async loginUser(passport) {
    let result = {
      valid: false,
      status: 0,
      messageText: "",
      token: "",
    };
    try {
      let response = await axios.post(this.#baseUrl + "/login", passport, {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      this.data = response.data;
      if (this.data.success) {
        result.valid = response.data.success;
        result.status = 200;
        result.messageText = "Usuario autorizado...";
        result.token = this.data.access_token;
        axios.defaults.headers['Authorization'] = `Bearer ${result.token}`;
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        this.error_message = err.response.data.message;
      }
      result.valid = err.response.data.success;
      result.status = err.response.status;
      result.messageText = err.response.data.message;
      result.token = "";
    }
    return result;
  }

  redirectToDashboard(token) {
    axios
      .get(this.#baseUrl + "/user", {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + this.data.access_token,
        },
      })
      .then((response) => {
        this.user = response.data;
        if (this.user.roles === "ADMIN" || this.user.roles === "USER") {
          store.commit("SetUser", this.user);
          store.commit("SetIslogin", true);
          store.commit("SetToken", token);
          router.push({ path: "/admin-page" });
        } else {
          this.error_message = response.data.status;
        }
      });
  }

  logoutUser() {
    axios
      .get(this.#baseUrl + "/logout", {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          "Authorization": "Bearer " + store.getters.getToken,
        },
      })
      .then((response) => {
        this.status = response.data.status;
        if (this.status === "success") {
          store.dispatch("clearStateLogin");
          store.commit("resetStateLogin");
          sessionStorage.clear();
          router.push({ path: "/homepage" });
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 400) {
          this.error_message = e.response.data.error;
        }
      });
  }
})();
