<template>
  <div>
    <v-row>
      <v-col
        class="d-none d-md-flex align-center justify-center"
        cols="12"
        lg="7"
        xl="6"
        style="height: 100vh; background-color: #691e32"
      >
        <v-container>
          <div>
            <v-row justify="center">
              <v-col cols="8" xl="5">
                <div>
                  <img
                      src="@/assets/images/logo_blanco.png"
                      style="max-height: 100px"
                  />

                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
      <v-col class="d-flex align-center" cols="12" lg="5" xl="6">
        <v-container>
          <div class="pa-7 pa-sm-12">
            <v-row>
              <v-col cols="12" lg="9" xl="6">
                <h2
                  class="font-weight-bold mt-4 blue-grey--text text--darken-2"
                >
                  Autenticaci&oacute;n
                </h2>
                <h6 v-show="false" class="subtitle-1">
                  ¿No tiene una cuenta a&uacute;n?
                  <a class href="/under-construction">Regístrese</a>
                </h6>

                <v-form
                  ref="form"
                  v-model="valid"
                  action="/admin/wireframe"
                  lazy-validation
                >
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    class="mt-4"
                    label="Correo"
                    outlined
                    required
                    @keypress.enter="submit"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :counter="10"
                    :rules="passwordRules"
                    :type="showPassword ? 'text' : 'password'"
                    label="Contraseña"
                    outlined
                    required
                    @keypress.enter="submit"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>

                  <!--<div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                                        <v-checkbox
                                                v-model="checkbox"
                                                label="Recordarme?"
                                                required
                                        ></v-checkbox>
                                        <div class="ml-auto">
                                            <a href="javascript:void(0)" class="link">Olvidó la contraseña?</a>
                                        </div>
                                    </div>-->

                  <v-btn
                    :disabled="!valid"
                    block
                    class="mr-4"
                    style="background-color: #691e32; color: white"
                    submit
                    @click="submit"
                  >
                    Entrar
                  </v-btn>
                </v-form>
                <div class="text-center mt-6">
                  <!--mdi-home-circle-->
                  <v-chip class="mr-2" pill @click="goHome">
                    <v-avatar left>
                      <v-btn class="white--text" color="primary lighten-1">
                        <v-icon>mdi-home</v-icon>
                      </v-btn>
                    </v-avatar>
                    Ir al Inicio
                  </v-chip>
                  <v-chip class="mr-2" pill v-show="false">
                    <v-avatar left>
                      <v-btn class="white--text" color="primary lighten-1">
                        <v-icon>mdi-twitter</v-icon>
                      </v-btn>
                    </v-avatar>
                    Twitter
                  </v-chip>
                  <v-chip pill v-show="false">
                    <v-avatar left>
                      <v-btn class="white--text" color="primary lighten-1">
                        <v-icon>mdi-facebook</v-icon>
                      </v-btn>
                    </v-avatar>
                    Facebook
                  </v-chip>
                  <div style="height: 70px"></div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogError" max-width="600" persistent>
        <v-card>
          <v-card-title class="text-h6 primary lighten-2">
            <span class="headline">Error de autentificaci&oacute;n</span>
          </v-card-title>

          <v-card-text>
            <div class="text-h4 pa-12">{{ messageError }}!</div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn text @click.stop="closeDialogError"> Cerrar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row>
      <v-dialog v-model="progressDialog" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Validando las credenciales...
            <v-progress-linear
              class="mb-0"
              color="white"
              indeterminate
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import loginService from "@/providers/LoginService";

export default {
  name: "FormLoginComponent",
  data: () => ({
    valid: true,
    showPassword: false,
    progressDialog: false,
    dialogError: false,
    messageError: "",
    email: "",
    checkbox: false,
    emailRules: [
      (v) => !!v || "El Correo Electrónico es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "La contraseña es obligatoria",
      (v) =>
        (v && v.length >= 10) ||
        "La contraseña debe tener al menos de 10 caracteres",
    ],
  }),
  watch: {
    dialogError(val) {
      val || this.closeDialogError();
    },
  },
  methods: {
    goHome() {
      this.$store.dispatch("clearState");
      this.$router.push({ path: "/homepage" });
    },
    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.progressDialog = true;
        /*if (this.email === 'admin@bhc.com' && this.password === 'desarrollo') {
                    console.log('Accediendo a la pagina de administracion.');
                    this.$store.state.user = this.email;
                    this.$store.state.isLogin = true;
                    this.$router.push({path: "/admin-page/dashboard"});
                }*/
        this.doLogin();
      }
    },
    doLogin() {
      let passport = {
        email: this.email,
        password: this.password,
      };
      loginService.loginUser(passport).then((response) => {
        this.progressDialog = false;
        this.dialogError = false;
        if (response.valid) {
          this.closeDialogError();
          sessionStorage.setItem('token', response.token);
          loginService.redirectToDashboard(response.token);
        } else {
          this.dialogError = true;
          this.messageError = response.messageText;
        }
      });
    },
    closeDialogError() {
      this.dialogError = false;
      this.messageError = "";
    },
  },
};
</script>
